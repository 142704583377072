<template>
    <div class="calendar-container">
        <a-card>
            <div class="d-flex align-items-center">
                <div>
                    <a-button type="primary" @click="addYear(false)">
                        <a-icon type="left"/>
                        <span>上一年</span>
                    </a-button>
                </div>
                <div style="padding: 0 10px">
                    <a-date-picker mode="year" @openChange="openChange" :defaultValue="momentYear?momentYear:null"
                                   :value="momentYear?momentYear:null" :open="isOpen" format="YYYY"
                                   @panelChange="handleChange"/>
                    <br/>
                </div>
                <div>
                    <a-button type="primary" @click="addYear(true)">
                        <span>下一年</span>
                        <a-icon type="right"/>
                    </a-button>
                </div>
                <div class="status-wrap d-flex justify-content-center">
                    <a-tag v-for="(item,index) in periodList" :key="item.key" :color="index ===0?'#108ee9':(index ===1 ?'#87d068':'pink')">
                        {{item.label}}
                    </a-tag>
                </div>
            </div>
            <div class="rili d-flex justify-content-between">
                <div class="calendar" v-for="(items,index) in monthsList" :key="index">
                    <div class="calendar_title">{{index+1}}月</div>
                    <div class="calendar_week">
                        <span style="color: red">日</span><span>一</span><span>二</span><span>三</span><span>四</span><span>五</span><span
                            style="color:red;">六</span>
                    </div>
                    <div class="calendar_dateCon d-flex flex-wrap">
                        <div class="day-wrap d-flex flex-column justify-content-center" v-for="(item,index2) in items" :key="index2"
                             :class="{green:item.class==='green',red:item.class==='red',
                             blue:item.class==='blue',weekend:(item.weekDay === 0 || item.weekDay === 6),
                             issunday:item.weekDay === 0,'near-last-seven-day':item.nearLastSevenDay,
                             hasborder:item.date,lastseven:item.lastSevenDay,lastday:item.theLastDay,firstrow:index2<7}"
                             :title="item.description"
                             @click="handleClick(item)">
                            <p>{{item.day}}</p>
                            <div class="description" v-if="item.description">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </a-card>
    </div>
</template>

<script>

    import saveDateModal from "./components/save-date-modal.vue"
    import {ModalHelper} from "../../../shared/helpers/modal";
    import {DataItemDetailServiceProxy, EnterpriseCalendarServiceServiceProxy} from "../../../shared/service-proxies";
    import {AppComponentBase} from "../../../shared/component-base";
    import moment from 'moment'

    export default {
        name: "businessCalendar",
        mixins: [AppComponentBase],
        data() {
            return {
                momentYear: null,
                year: '',//选择的年份
                monthsList: [],//月份列表
                periodList: [],//字典中假期类型
                // yearList: [],
                defaultValue: 0,//默认选择的年份
                initYear: undefined,
                dayData: [],//获得今年的数据
                periodIndex: '',//模态框所选择的时间段
                checkedDate: '',//选择的日期
                description: '',//描述
                isOpen: false
            }
        },
        computed: {
            //是否闰年
            isRun() {
                if ((this.year % 400 == 0) || (this.year % 4 == 0) && (this.year % 100 != 0)) {
                    return true;
                } else {
                    return false;
                }
            },

        },
        components: {
            saveDateModal
        },
        created() {
            this.initYear = new EnterpriseCalendarServiceServiceProxy(this.$apiUrl, this.$api);
            this.dictionaryList = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
             this.getDicList()
        },
        mounted() {
            this.year = (new Date()).getFullYear()
            this.momentYear = moment(this.year + '-01-01')
            this.getTheMonth()
            // this.setYearList(this.year)
        },
        methods: {
            //获取字典中假期类型的字典
            getDicList() {
                this.dictionaryList.getListByDataItmeCode('Att_WorkType').then(res => {
                    res.forEach(item => {
                        this.periodList.push(
                            {
                                key: item.id,
                                label: item.itemDetailName
                            }
                        )
                    })
                })
            },
            //下拉选中年份
            handleChange(value) {
                this.year = value.format('YYYY')
                this.momentYear = value
                this.isOpen = false
                // this.year = value
                this.getTheMonth()
            },
            addYear(add) {
                if (add) {
                    this.year++
                } else {
                    this.year--
                }
                this.momentYear = moment(this.year + '-01-01')
                this.getTheMonth()
            },
            //是否打开选择
            openChange(e) {
                if (e) {
                    this.isOpen = true
                } else {
                    this.isOpen = false
                }
            },
            //生成日历
            getTheMonth() {
                let year = this.year;
                let dayMseconds = 86400000;
                let monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
                let resultArr = [];
                let run = this.isRun;
                for (let m = 1; m < 13; m++) {
                    let arr = [];
                    let firstday = new Date(year + "-" + m + "-01").getTime();//拿到时间戳
                    let lastday = 30;
                    let weekDay = new Date(firstday).getDay();      //根据时间戳，拿到本月第一天的星期
                    let weekLastDay = weekDay + 31;   //拿到本月最后一天的星期
                    switch (m) {
                        case 2:
                            if (run) {
                                lastday = new Date(year + "-" + m + "-29").getTime();
                                weekLastDay = weekDay + 29;
                            } else {
                                lastday = new Date(year + "-" + m + "-28").getTime();
                                weekLastDay = weekDay + 28;
                            }
                            break;
                        case 4:
                            lastday = new Date(year + "-" + m + "-30").getTime();
                            weekLastDay = weekDay + 30;
                            break;
                        case 6:
                            lastday = new Date(year + "-" + m + "-30").getTime();
                            weekLastDay = weekDay + 30;
                            break;
                        case 9:
                            lastday = new Date(year + "-" + m + "-30").getTime();
                            weekLastDay = weekDay + 30;
                            break;
                        case 11:
                            lastday = new Date(year + "-" + m + "-30").getTime();
                            weekLastDay = weekDay + 30;
                            break;

                        default :
                            lastday = new Date(year + "-" + m + "-31").getTime();//拿到时间戳
                            weekLastDay = weekDay + 31;
                            break;

                    }

                    let n = 0;
                    for (let i = 0; i < 42; i++) {

                        let content = {date: '', class: ''};
                        if (i < weekDay || i > weekLastDay - 1) {
                            //判断是否是靠近本月最后七天的一周，可以给这一周加上样式
                            if(i > weekLastDay -1 && i <weekLastDay + 7){
                                content.nearLastSevenDay = true;
                                arr.push(content);
                            }else {

                                arr.push(content);
                            }
                        } else {
                            n++
                            //判断是不是最后七天，可以给最后七天加上样式
                            if (i < weekLastDay - 7) {
                                arr.push(
                                    {
                                        day: new Date(firstday + dayMseconds * n - dayMseconds).getDate(),
                                        date: firstday + dayMseconds * n - dayMseconds,
                                        class: '',
                                        lastSevenDay: false
                                    }
                                )
                            } else {
                                //判断是不是最后一天，可以给最后一天加样式
                                if (i === weekLastDay - 1) {
                                    arr.push(
                                        {
                                            day: new Date(firstday + dayMseconds * n - dayMseconds).getDate(),
                                            date: firstday + dayMseconds * n - dayMseconds,
                                            class: '',
                                            lastSevenDay: true,
                                            theLastDay: true
                                        }
                                    )
                                } else {
                                    arr.push(
                                        {
                                            day: new Date(firstday + dayMseconds * n - dayMseconds).getDate(),
                                            date: firstday + dayMseconds * n - dayMseconds,
                                            class: '',
                                            lastSevenDay: true
                                        }
                                    )
                                }

                            }

                        }

                    }
                    resultArr.push(arr);

                }
                this.monthsList = resultArr;
                this.setColor()
            },
            //点击日期
            handleClick(data) {
                //存在class表示被设置过，不存在class表示没又被设置过，设置过的就还原当前的状态，否则就是添加新的设置
                if (data.class) {
                    // 根据被点击的class，判断模态框里的选中的是哪个类型
                    if (data.class === 'green') {
                        this.periodIndex = 2
                    } else if (data.class === 'blue') {
                        this.periodIndex = 0
                    } else if (data.class === 'red') {
                        this.periodIndex = 1
                    }
                    //编辑的情况下，设置模态框里的日期

                    this.description = data.description
                    // let checkedData = new Date(data.date)
                    // this.checkedDate = checkedData.getFullYear() + '-' + (checkedData.getMonth() + 1) + '-' + checkedData.getDate()

                } else {
                    this.periodIndex = '';
                    // this.checkedDate = '';
                    this.description = '';
                }
                let checkedData = new Date(data.date);
                this.checkedDate = checkedData.getFullYear() + '-' + (checkedData.getMonth() + 1) + '-' + checkedData.getDate()
                if (data.date) {
                    ModalHelper.create(saveDateModal, {
                        itemData:data,
                        // periodList: this.periodList,
                        // periodIndex: this.periodIndex,
                        // isEdit: data.class ? true : false,
                        checkedDate: this.checkedDate,
                        // description: this.description
                    }, {
                        width:"500px"
                    }).subscribe(res => {
                        if (res) {
                            this.getTheMonth()
                        }
                    })
                }

            },
            //从接口里获取一年内的各种设置
            setColor() {
                this.initYear.getEnterpriseCalendarList(this.year).then(res => {
                    this.dayData = res
                    this.monthsList.forEach(items => {
                        items.forEach(item => {
                            this.$set(item, 'weekDay', new Date(item.date).getDay())
                            this.dayData.forEach(oneDay => {
                                let dayFormat = oneDay.calendarDate.format('YYYY-MM-DD')
                                // let dayStamp = new Date(dayFormat)
                                if (dayFormat === moment(item.date).format("YYYY-MM-DD")) {

                                    //设置相应的class
                                    if (oneDay.workType.itemDetailName.indexOf('周末') !== -1) {
                                        item.description=oneDay.description;
                                        item.class = 'green'
                                    } else if (oneDay.workType.itemDetailName.indexOf('法定') !== -1) {
                                        item.description=oneDay.description;
                                        item.class = 'red'
                                    } else if (oneDay.workType.itemDetailName.indexOf('工作日') !== -1) {
                                        item.description=oneDay.description;
                                        item.class = 'blue'
                                    }
                                    // this.$set(item, 'description', oneDay.description)
                                }
                            })
                        });
                    })
                })
            }
        }
    }
</script>

<style scoped>
    p {
        margin: 0;
        padding: 0;
    }

    .calendar-container {
        background: #ffffff;
    }

    .rili {
        /*float: left;*/
        /*padding-left: 20px;*/
        font-size: 12px;
        overflow: hidden;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .calendar {
        /*float: left;*/
        width: 22%;
        flex-shrink: 0;

        border: 1px solid #5caeff;
        /*margin-right: 20px;*/
        /*margin-top: 20px;*/
        margin-bottom: 10px;
        /*height: 220px;*/
    }

    .calendar .calendar_title {
        padding: 0.5em 0 0.5em 0;
        text-align: center;
        background: #5caeff;

        border-bottom: 1px solid #BEBFC1;
    }

    .calendar .calendar_week {
        padding: 0.5em 0 0.5em 0;
        background:rgba(24,144,255,.2);
        border-bottom: 1px solid #5caeff;
    }

    .calendar .calendar_week span {
        width: 14.2857%;
        text-align: center;
        display: inline-block;
    }

    .calendar .calendar_dateCon .day-wrap {
        /*border: 1px solid red;*/
        /*padding: 0.5em 0 0.5em 0;*/
        width: 14.2857%;
        height: 40px;
        text-align: center;

        /*display: block;*/
        /*float: left;*/
    }

    .calendar .calendar_dateCon .hasborder {
        cursor: pointer;
        border-left: 1px solid #5caeff;
        border-top: 1px solid #5caeff;
    }
    .calendar .calendar_dateCon .near-last-seven-day{
        border-top: 1px solid #5caeff;
    }
    /*.calendar .calendar_dateCon .lastseven {*/
    /*    border-bottom: 1px solid #5caeff;*/
    /*}*/

    .calendar .calendar_dateCon .lastday {
        /*border-right: 1px solid #5caeff;*/
        position: relative;
    }
    .calendar .calendar_dateCon .lastday:after{
        content: '';
        display: block;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 1px;
        height: 40px;
        background: #5caeff;
    }

    .calendar .calendar_dateCon .issunday {
        border-left: none;
    }
    .calendar .calendar_dateCon .firstrow{
        border-top: none;
    }


    .calendar .calendar_dateCon .day-wrap .description {
        height: 20px;
        font-size: 10px;
        transform: scale(0.8);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .status-wrap {
        /*width: 100%;*/
        margin-left: 20px;
    }


    .green {
        background: rgb(135, 208, 104);
        color: #FFFFFF;
    }

    .blue {
        background: rgb(16, 142, 233);
        color: #FFFFFF;

    }

    .red {
        background: #fff0f6;
        color: red;

    }

    .weekend {
        color: red;
    }
</style>
