<template>
	<a-spin :spinning="spinning">
		<div class="modal-header">
			<div class="modal-title">
				<div v-if="!isEdit">新增节日</div>
				<div v-else>编辑节日</div>
			</div>
		</div>
		<a-config-provider :locale="zhCN">
			<a-row>
				<a-row>
					<a-col :span="4">
						<q-label label="类型" required></q-label>
					</a-col>
					<a-col :span="16">
						<a-select v-model="entity.workTypeCode" style="width: 100%" placeholder="请选择节日类型">
							<a-select-option v-for="item in periodList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label label="时间" required></q-label>
					</a-col>
					<a-col :span="16">
						<a-range-picker format="YYYY-MM-DD" v-model="startAndEndDate" @change="onChange" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label label="描述"></q-label>
					</a-col>
					<a-col :span="16">
						<a-input placeholder="请输入备注" v-model="entity.description" />
					</a-col>
				</a-row>
			</a-row>
		</a-config-provider>
		<div v-if="isEdit">
			<a-row>
				<a-col :span="16" :offset="4" class="editor">
					<a-button :type="'primary'" @click="clearData">
						清空
					</a-button>
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { EnterpriseCalendarDtoInput, EnterpriseCalendarServiceServiceProxy } from '../../../../shared/service-proxies';
import moment from 'moment';
import { Dic } from '../../../../shared/utils';

export default {
	name: 'save-date-modal',
	mixins: [ModalComponentBase],
	props: {},
	data() {
		return {
			periodList: [], //放假类型列表
			id: undefined, //编辑还是新增
			zhCN,
			spinning: false, //加载中
			createApi: undefined,
			entity: new EnterpriseCalendarDtoInput(),
			startAndEndDate: [],
			itemData: undefined,
			checkedDate: undefined,
			isEdit: false,
		};
	},
	watch: {},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._enterpriseCalendarServiceServiceProxy = new EnterpriseCalendarServiceServiceProxy(
			this.$apiUrl,
			this.$api
		);
	},
	async mounted() {
		this.periodList = await Dic.getInstance().getDicAsync('Att_WorkType');
		if (this.checkedDate) {
			this.getData();
			this.startAndEndDate = [
				moment(this.checkedDate).format('YYYY-MM-DD'),
				moment(this.checkedDate).format('YYYY-MM-DD'),
			];
			this.entity.startTime = moment(this.checkedDate);
			this.entity.endTime = moment(this.checkedDate);
		}
	},
	methods: {
		getData() {
			this.isEdit = false;
			this.spinning = true;
			this.startAndEndDate = [];
			this._enterpriseCalendarServiceServiceProxy
				.getForEdit(moment(this.checkedDate))
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res.workTypeCode) {
						this.entity.workTypeCode = res.workTypeCode;
						// this.entity.startTime=res.calendarDate;
						// this.entity.endTime=res.calendarDate;
						this.entity.description = res.description;
						this.startAndEndDate = [
							moment(res.calendarDate).format('YYYY-MM-DD'),
							moment(res.calendarDate).format('YYYY-MM-DD'),
						];
						this.isEdit = true;
					}
				});
		},
		onChange(date) {
			if (date.length > 0) {
				this.entity.startTime = date[0];
				this.entity.endTime = date[1];
			} else {
				this.entity.startTime = undefined;
				this.entity.endTime = undefined;
			}
		},
		create() {
			this.entity.startTime = this.entity.startTime;
			this.entity.endTime = this.entity.endTime;
			this.spinning = true;
			this._enterpriseCalendarServiceServiceProxy
				.create(this.entity)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
		update() {
			this.entity.startTime = this.entity.startTime;
			this.entity.endTime = this.entity.endTime;
			this.spinning = true;
			this._enterpriseCalendarServiceServiceProxy
				.update(this.entity)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
		save() {
			if (!this.entity.workTypeCode) {
				return abp.message.warn('请选择类型');
			}
			if (!this.entity.startTime || !this.entity.endTime) {
				return abp.message.warn('请选择时间');
			}
			this.entity.startTime = this.entity.startTime;
			this.entity.endTime = this.entity.endTime;
			if (!this.isEdit) {
				this.create();
			} else {
				this.update();
			}
		},
		clearData() {
			this.spinning = true;
			this.entity.startTime = this.entity.startTime;
			this.entity.endTime = this.entity.endTime;
			this._enterpriseCalendarServiceServiceProxy
				.empty(this.entity.startTime, this.entity.endTime)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
